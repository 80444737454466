<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="grey lighten-3">
            <template #default>
                <div class="d-flex justify-space-between align-center">
                    <div class="text-h6 d-flex align-center">
                        <v-icon small left>fas fa-first-aid fa-fw</v-icon>
                        {{ $t('labels.conditions') }}
                    </div>
                    <v-icon small class="mx-2 secondary--text" v-if="isValid">fas fa-check</v-icon>
                    <div class="mx-2 text-right" v-else>
                        <span class="mr-1">{{ $t('common.required') }}:</span>
                        <span class="error--text">{{ $t('labels.addConditions') }}</span>
                        <em class="secondary--text"> {{ $t('common.or') }} </em>
                        <span class="error--text">{{ $t('labels.checkHealthy') }}</span>
                        <em class="secondary--text" v-if="!isSelf"> {{ $t('common.or') }} </em>
                        <span class="error--text" v-if="!isSelf">{{ $t('labels.checkUnknown') }}</span>
                    </div>
                </div>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager class="expansion-panel--padless">
            <div :class="{ 'error-highlight': !isValid }">
                <v-toolbar dense dark flat color="primary">
                    <v-btn small text outlined class="btn-outline-solid-white" @click.stop="addConditions()" :disabled="!!medicalHistory" id="tour-conditions-add">
                        <v-icon left small>fas fa-plus</v-icon>
                        {{ $t('labels.addConditions') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <div id="tour-conditions-healthy">
                        <v-checkbox v-model="medicalHistory" :true-value="'healthy'" :false-value="null" hide-details class="mx-3" small :disabled="person.conditions.length > 0">
                            <template #label>
                                {{ $t('medicalHistories.healthy.label') }}
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-icon small right v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
                                    </template>
                                    <span>{{ $t('medicalHistories.healthy.hint') }}</span>
                                </v-tooltip>
                            </template>
                        </v-checkbox>
                    </div>
                    <v-checkbox v-model="medicalHistory" :true-value="'unknown'" :false-value="null" hide-details class="mx-3" small :disabled="person.conditions.length > 0" v-if="!isSelf">
                        <template #label>
                            {{ $t('medicalHistories.unknown.label') }}
                            <v-tooltip top>
                                <template #activator="{ on, attrs }">
                                    <v-icon small right v-bind="attrs" v-on="on">fas fa-info-circle</v-icon>
                                </template>
                                <span>{{ $t('medicalHistories.unknown.hint') }}</span>
                            </v-tooltip>
                        </template>
                    </v-checkbox>
                </v-toolbar>
            </div>
            <div v-if="!medicalHistory">
                <v-list class="condition-list" v-if="person.conditions && person.conditions.length">
                    <template v-for="(condition, index) in person.conditions">
                        <v-list-item :key="condition.uuid" @click="update(person, condition)">
                            <v-list-item-action class="my-0 mr-3">
                                <v-btn icon small color="primary" @click.stop="remove(person, condition)"><v-icon small>fas fa-trash</v-icon></v-btn>
                            </v-list-item-action>
                            <v-list-item-content class="py-0">
                                <v-list-item-title>{{ $t(`conditions.${condition.id}.name`) }}</v-list-item-title>
                            </v-list-item-content>
                            <ConditionChips :condition="condition" :person="person" v-if="!condition.skipQuestions"></ConditionChips>
                        </v-list-item>
                        <v-divider :key="index" v-if="index < person.conditions.length - 1"></v-divider>
                    </template>
                </v-list>
                <v-dialog v-model="dialog" :max-width="800" eager scrollable id="tour-conditions-dialog">
                    <v-card class="d-flex flex-column">
                        <v-toolbar dense dark color="primary" class="flex-grow-0">
                            <v-toolbar-title>
                                <div class="d-flex align-center">
                                    <v-icon small left>fas fa-first-aid fa-fw</v-icon>
                                    {{ $t('labels.addConditions') }}
                                </div>
                            </v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn small text outlined class="btn-outline-solid-white" @click="dialog = false" id="tour-conditions-done">
                                <v-icon small left>fas fa-check</v-icon>
                                {{ $t('labels.doneAddingConditions') }}
                            </v-btn>
                        </v-toolbar>
                        <div class="my-2 px-4">
                            <div id="tour-conditions-search">
                                <v-text-field
                                    dense
                                    outlined
                                    hide-details
                                    clearable
                                    autofocus
                                    clear-icon="fas fa-times"
                                    v-model="searchText"
                                    @input="search"
                                    prepend-inner-icon="fas fa-search"
                                    :placeholder="$t('common.search_for_condition')"
                                ></v-text-field>
                            </div>
                            <v-row no-gutters align="center" class="my-2 mx-6">
                                <v-col class="ml-6">
                                    <v-divider class="primary"></v-divider>
                                </v-col>
                                <v-col cols="auto" class="mx-6">
                                    <span class="body-2 primary--text">{{ $t('common.or') }}</span>
                                </v-col>
                                <v-col class="mr-6">
                                    <v-divider class="primary"></v-divider>
                                </v-col>
                            </v-row>
                            <v-card outlined class="my-2 primary--border" id="tour-conditions-filter">
                                <div class="py-2 px-3 primary--text">
                                    <v-icon small left color="primary">fas fa-filter fa-fw</v-icon>
                                    {{ $t('common.filter_by_category') }}
                                </div>
                                <v-divider class="primary"></v-divider>
                                <div class="pa-2">
                                    <v-btn
                                        small
                                        outlined
                                        rounded
                                        color="primary"
                                        class="filter-button ma-1"
                                        v-for="(category, index) in categories"
                                        :key="index"
                                        :input-value="selectedCategoryIndex === index"
                                        :disabled="!countByCategory(category)"
                                        @click="filter(index)"
                                    >
                                        <div class="d-flex align-center">
                                            <v-icon small left v-if="categoryIcon(category)">fas {{ categoryIcon(category) }} fa-fw</v-icon>
                                            {{ $t(`categories.${category}`) }}
                                            <div class="text-caption hint-text ml-1">{{ countByCategory(category) }}</div>
                                        </div>
                                    </v-btn>
                                </div>
                            </v-card>
                        </div>
                        <div class="d-flex align-center justify-space-between mx-6">
                            <div class="text-subtitle-1 hint-text">{{ $t('hints.addConditions') }}</div>
                            <div class="text-caption hint-text">{{ $tc('common.results', filteredConditions.length) }}</div>
                        </div>
                        <v-divider></v-divider>
                        <v-card-text class="pa-0" style="height: 416px" v-if="filteredConditions && filteredConditions.length" id="tour-conditions-list">
                            <v-virtual-scroll :bench="8" :items="filteredConditions" :item-height="64" :max-height="416" v-tournoscroll>
                                <template #default="{ item: condition }">
                                    <v-list-item :key="condition.id" @click="add(person, condition)" style="height: 64px" :class="{ disabled: !canAdd(person, condition) }" :disabled="!canAdd(person, condition)">
                                        <v-list-item-action class="my-0 mr-3" id="tour-conditions-info">
                                            <v-btn icon small color="primary" :class="{ hidden: !hasSnomed(condition) }" @click.stop="$root.$medlinePlus.show(condition)">
                                                <v-icon small>fas fa-info-circle</v-icon>
                                            </v-btn>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title class="primary--text">{{ $t(`conditions.${condition.id}.name`) }}</v-list-item-title>
                                            <v-list-item-subtitle class="wrap" v-html="$t(`conditions.${condition.id}.hint`)"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action-text v-if="familyConditions[condition.id]">
                                            <v-chip label small v-if="1 === familyConditions[condition.id].length">
                                                {{ familyConditions[condition.id][0].nickname || $t(`relations.${familyConditions[condition.id][0].relation}`) }}
                                            </v-chip>
                                            <v-chip label small v-else-if="familyConditions[condition.id].length > 1">
                                                {{ $tc('common.family_members', familyConditions[condition.id].length) }}
                                            </v-chip>
                                        </v-list-item-action-text>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </template>
                            </v-virtual-scroll>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import { ConditionsMixin } from '@/components/ConditionsMixin';
    import { sync, FamilyMemberMixin } from '@/components/FamilyMemberMixin';
    import ConditionChips from '@/components/ConditionChips';
    import { categories, conditions } from '@/common';
    import { each, filter, find, includes, reduce, some, uniqBy } from 'lodash';
    import { get } from 'vuex-pathify';

    export default {
        mixins: [ConditionsMixin, FamilyMemberMixin],
        components: { ConditionChips },
        data: () => ({
            dialog: false,
            selectedCategoryIndex: null,
            searchText: '',
            categories,
            conditions
        }),
        computed: {
            isValid() {
                return this.$store.get('isConditionsValid', this.uuid);
            },
            family: get('family'),
            medicalHistory: sync('medicalHistory'),
            studyConditions: get('patient/studyConditions'),
            otherConditions() {
                return filter(this.studyConditions, (condition) => /^other/.test(condition.id));
            },
            decoratedConditions() {
                return this.studyConditions.map((condition) => {
                    return Object.assign(condition, {
                        name: this.$i18n.t(`conditions.${condition.id}.name`),
                        hint: this.$i18n.t(`conditions.${condition.id}.hint`),
                        keywords: this.$i18n.t(`conditions.${condition.id}.keywords`),
                        categoriesText: condition.categories.map((category) => this.$i18n.t(`categories.${category}`))
                    });
                });
            },
            familyConditions() {
                let conditions = {};
                each(this.family, (person) => {
                    each(person.conditions, (condition) => {
                        if (!conditions[condition.id]) {
                            conditions[condition.id] = [];
                        }
                        if (!find(conditions[condition.id], { uuid: person.uuid })) {
                            conditions[condition.id].push(person);
                        }
                    });
                });
                return conditions;
            },
            selectedCategory() {
                return this.selectedCategoryIndex !== null ? this.categories[this.selectedCategoryIndex] : null;
            },
            filteredConditionsBySearchText() {
                if (this.searchText) {
                    const regexp = new RegExp(this.searchText, 'i');
                    return filter(this.decoratedConditions, (condition) => {
                        return some(condition, (val, key) => {
                            if ('meta' === key) {
                                return false;
                            }
                            return regexp.test(val);
                        });
                    });
                }
                return this.studyConditions;
            },
            filteredConditions() {
                let filteredConditions = this.filteredConditionsBySearchText;
                if (this.selectedCategory) {
                    filteredConditions = filter(this.filteredConditionsBySearchText, (condition) => {
                        return includes(condition.categories, this.selectedCategory);
                    });
                }
                if (filteredConditions && filteredConditions.length) {
                    return uniqBy(filteredConditions.concat(this.otherConditions), 'id');
                }
                return this.otherConditions;
            }
        },
        methods: {
            setDialog(val) {
                this.dialog = val;
            },
            addConditions() {
                this.searchText = '';
                this.selectedCategoryIndex = null;
                this.dialog = true;
            },
            filter(index) {
                if (this.selectedCategoryIndex === index) {
                    this.selectedCategoryIndex = null;
                } else {
                    this.selectedCategoryIndex = index;
                }
            },
            search() {
                if (this.selectedCategory && !this.countByCategory(this.selectedCategory)) {
                    this.selectedCategoryIndex = null; // de-select current selected category if count is zero
                }
            },
            countByCategory(category) {
                return reduce(
                    this.filteredConditionsBySearchText,
                    (count, condition) => {
                        return count + (includes(condition.categories, category) ? 1 : 0);
                    },
                    0
                );
            }
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep .filter-button {
        text-transform: none;
    }
    .wrap {
        white-space: normal;
    }
</style>
