const moment = require('moment');
const { v4: uuidv4 } = require('uuid');
const { cloneDeep, each, find, includes, isNumber, keyBy, omit, sortBy, trim } = require('lodash');
const { conditionSnomed } = require('./snomed');
const countryRegionData = require('country-region-data/dist/data-umd');

exports.wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

exports.dataUri = (buffer, contentType = 'image/png', encoding = 'base64') => {
    return `data:${contentType};${encoding},` + buffer.toString(encoding);
};

exports.dataUriParse = (dataUri) => {
    const match = (dataUri || '').match(/^data:(?<contentType>.*);(?<encoding>.*),(?<data>.*)$/i);
    if (match?.groups?.contentType && match?.groups?.encoding && match?.groups?.data) {
        const data = Buffer.from(match.groups.data, match.groups.encoding).toString('ascii');
        if ('application/json' === match.groups.contentType) {
            return JSON.parse(data);
        }
        return data;
    }
    return null;
};

exports.countries = sortBy(countryRegionData, (country) => {
    if ('US' === country.countryShortCode) {
        return '1';
    } else if ('CA' === country.countryShortCode) {
        return '2';
    } else if ('MX' === country.countryShortCode) {
        return '3';
    }
    return country.countryName;
});

const countriesByCode = {};
const regionsByCountryCode = {};
each(exports.countries, (country) => {
    countriesByCode[country.countryShortCode] = country;
    regionsByCountryCode[country.countryShortCode] = sortBy(country.regions, 'name');
});
exports.countriesByCode = countriesByCode;
exports.regionsByCountryCode = regionsByCountryCode;

exports.parseCountry = (country) => {
    if (country && 2 === country.length) {
        return country;
    }
    let _country = country;
    each(exports.countries, (c) => {
        const regex = new RegExp('^' + country + '$', 'i');
        if (regex.test(c.countryName)) {
            _country = c.countryShortCode;
            return false;
        }
    });
    return _country || 'US';
};

exports.parseState = (state, country = 'US') => {
    if (/^US$|^CA$/.test(country) && state && 2 === state.length) {
        return state;
    }
    let _state = state;
    if (country) {
        let _country = find(exports.countries, { countryShortCode: country });
        if (_country) {
            each(_country.regions, (region) => {
                const regex = new RegExp('^' + state + '$', 'i');
                if (regex.test(region.name)) {
                    _state = region.shortCode;
                    return false;
                }
            });
        }
    }
    return _state;
};

exports.colors = {
    primary: '#114470',
    secondary: '#92b2bb',
    accent: '#bcb0a0',
    info: '#92b2bb'
};

exports.LBS_TO_KG = 0.45359237;
exports.IN_TO_CM = 2.54;
exports.MGPERDL_TO_MMOLPERL = 1 / 18.0182;
exports.MGPERDL_TO_MGPERL = 0.1;

const createFamilyMember = ({ relation, gender, father = null, mother = null, nickname = null }) => ({
    uuid: uuidv4(),

    // self-only
    firstName: '',
    lastName: '',
    genderIdentity: '',
    language: 'en',
    email: '',
    address: '', // deprecated
    line: '',
    city: '',
    state: '',
    postalCode: '',
    country: 'US',
    phone: '',
    insurance: '',
    residenceYouth: '',
    residenceLast15Years: '',
    income: '',

    relation: relation,
    father: father,
    mother: mother,
    nickname: nickname,
    gender: gender,

    race: [],
    ethnicity: [],
    birthplace: '',
    education: '',
    work: [],
    otherWork: '',

    living: '',
    birthDate: null,
    age: null,
    causeOfDeath: '',
    ageAtDeath: null,
    ageAtDeathUnknown: null,

    multiple: null,
    adopted: null,
    cosanguineousParents: null,

    height: null,
    weight: null,
    waist: null,

    heightMode: 'in',
    weightMode: 'lbs',
    waistMode: 'in',

    tobacco: null,
    tobaccoPacksPerDay: null,
    tobaccoYears: null,
    tobaccoQuitYear: null,

    alcohol: null,
    alcoholDrinksPerWeek: null,
    alcoholYears: null,
    alcoholQuitYear: null,

    bpSystolic: null,
    bpDiastolic: null,
    ldl: null,
    hdl: null,
    triglycerides: null,
    totalCholesterol: null,
    cholesterolTestYear: null,
    cholesterolMedication: null,
    hsCRP: null,
    caIMT: null,
    cacCT: null,

    ldlMode: 'mg/dL',
    hdlMode: 'mg/dL',
    triglyceridesMode: 'mg/dL',
    totalCholesterolMode: 'mg/dL',
    hsCRPMode: 'mg/L',

    fruit: null,
    vegetable: null,
    sugar: null,
    grain: null,
    fat: null,
    moderateExercise: null,
    vigorousExercise: null,
    strengthTraining: null,

    cancerScreenConditions: [],
    pregnancyScreenConditions: [],
    medicalHistory: null,
    conditions: []
});
exports.createFamilyMember = createFamilyMember;

const identityFields = ['firstName', 'lastName', 'birthDate', 'gender'];
exports.identityFields = identityFields;

const initFamily = () => {
    return [createFamilyMember({ relation: 'SELF', gender: null })];
};
exports.initFamily = initFamily;

exports.calculateAge = (date, fromDate = new Date()) => {
    return date ? moment(fromDate).diff(moment(date), 'years') : null;
};

exports.fullName = ({ firstName = '', lastName = '' }) => {
    return trim(`${firstName} ${lastName}`);
};

exports.isPaternal = (relation) => {
    return 'NFTH' === relation || /^P/.test(relation);
};
exports.isMaternal = (relation) => {
    return 'NMTH' === relation || /^M/.test(relation);
};

exports.relations = [
    { code: 'SELF', sort: 1, gender: '-', cds: 'self', name: 'Self' },
    { code: 'SON', sort: 2, gender: 'M', cds: 'son', name: 'Son' },
    { code: 'DAU', sort: 3, gender: 'F', cds: 'daughter', name: 'Daughter' },
    { code: 'GRNDSON', sort: 4, gender: 'M', cds: 'grandSon', name: 'Grandson' },
    { code: 'GRNDDAU', sort: 5, gender: 'F', cds: 'grandDaughter', name: 'Granddaughter' },
    { code: 'NFTH', sort: 6, gender: 'M', cds: 'father', name: 'Father' },
    { code: 'NMTH', sort: 7, gender: 'F', cds: 'mother', name: 'Mother' },
    { code: 'NBRO', sort: 8, gender: 'M', cds: 'brother', name: 'Brother' },
    { code: 'HBRO', sort: 9, gender: 'M', cds: 'halfBrother', name: 'Half Brother' },
    { code: 'NSIS', sort: 10, gender: 'F', cds: 'sister', name: 'Sister' },
    { code: 'HSIS', sort: 11, gender: 'F', cds: 'halfSister', name: 'Half Sister' },
    { code: 'NEPHEW', sort: 12, gender: 'M', cds: 'nephew', name: 'Nephew' },
    { code: 'NIECE', sort: 13, gender: 'F', cds: 'niece', name: 'Niece' },
    { code: 'PGRFTH', sort: 14, gender: 'M', cds: 'paternalGrandfather', name: 'Paternal Grandfather' },
    { code: 'PGRMTH', sort: 15, gender: 'F', cds: 'paternalGrandmother', name: 'Paternal Grandmother' },
    { code: 'PUNCLE', sort: 16, gender: 'M', cds: 'paternalUncle', name: 'Paternal Uncle' },
    { code: 'PAUNT', sort: 17, gender: 'F', cds: 'paternalAunt', name: 'Paternal Aunt' },
    { code: 'PCOUSN', sort: 18, gender: '-', cds: 'paternalCousin', name: 'Paternal Cousin' },
    { code: 'MGRFTH', sort: 19, gender: 'M', cds: 'maternalGrandfather', name: 'Maternal Grandfather' },
    { code: 'MGRMTH', sort: 20, gender: 'F', cds: 'maternalGrandmother', name: 'Maternal Grandmother' },
    { code: 'MUNCLE', sort: 21, gender: 'M', cds: 'maternalUncle', name: 'Maternal Uncle' },
    { code: 'MAUNT', sort: 22, gender: 'F', cds: 'maternalAunt', name: 'Maternal Aunt' },
    { code: 'MCOUSN', sort: 23, gender: '-', cds: 'maternalCousin', name: 'Maternal Cousin' }
];

const requiredRelations = ['NMTH', 'MGRMTH', 'MGRFTH', 'NFTH', 'PGRMTH', 'PGRFTH'];

const generalRequired = (person) => {
    const required = [];
    if ('SELF' === person.relation) {
        const requiredFields = ['gender', 'birthDate'];
        requiredFields.forEach((field) => {
            if (!person[field]) {
                required.push(field);
            }
        });
    } else {
        if (!person.living) {
            required.push('living');
        } else if ('Deceased' === person.living) {
            if (!isNumber(person.ageAtDeath) && !person.ageAtDeathUnknown) {
                required.push('ageAtDeath');
            }
            if (!person.causeOfDeath) {
                required.push('causeOfDeath');
            }
        } else if ('Alive' === person.living) {
            if (!person.birthDate && (null === person.age || '' === person.age || Number.isNaN(person.age) || !isNumber(person.age))) {
                required.push(['birthDate', 'age']);
            }
        }
    }
    return required;
};
const isGeneralValid = (person) => {
    return 0 === generalRequired(person).length;
};

const demographicsRequired = (person) => {
    const required = [];
    if ('SELF' === person.relation) {
        const requiredFields = ['race', 'ethnicity'];
        requiredFields.forEach((field) => {
            if (!person[field].length) {
                required.push(field);
            }
        });
    }
    return required;
};
const isDemographicsValid = (person) => {
    return 0 === demographicsRequired(person).length;
};

// eslint-disable-next-line no-unused-vars
const isCancerValid = (person) => {
    return true;
};

// eslint-disable-next-line no-unused-vars
const isPregnancyValid = (person) => {
    return true;
};

// eslint-disable-next-line no-unused-vars
const isMetricsValid = (person) => {
    // if ('SELF' === person.relation) {
    //     return (
    //         !!person.height &&
    //         !!person.weight &&
    //         !!person.waist &&
    //         !!person.tobacco &&
    //         !!person.alcohol &&
    //         ('Y' === person.tobacco ? !!person.tobaccoPacksPerDay && !!person.tobaccoYears : true) &&
    //         ('Y' === person.alcohol ? !!person.alcoholDrinksPerWeek && !!person.alcoholYears : true)
    //     );
    // }
    return true;
};

// eslint-disable-next-line no-unused-vars
const isLabsValid = (person) => {
    return true;
};

// eslint-disable-next-line no-unused-vars
const isDietValid = (person) => {
    return true;
};

// eslint-disable-next-line no-unused-vars
const isExerciseValid = (person) => {
    return true;
};

const isCardiovascularValid = (person) => {
    return isMetricsValid(person) && isLabsValid(person) && isDietValid(person) && isExerciseValid(person);
};

const isConditionsValid = (person) => {
    return person.medicalHistory || person.conditions.length > 0;
};

const isValid = (person) => {
    return isGeneralValid(person) && isDemographicsValid(person) && isPregnancyValid(person) && isConditionsValid(person) && isCancerValid(person) && isCardiovascularValid(person);
};

exports.requiredRelations = requiredRelations;
exports.generalRequired = generalRequired;
exports.isGeneralValid = isGeneralValid;
exports.demographicsRequired = demographicsRequired;
exports.isDemographicsValid = isDemographicsValid;
exports.isCancerValid = isCancerValid;
exports.isPregnancyValid = isPregnancyValid;
exports.isMetricsValid = isMetricsValid;
exports.isLabsValid = isLabsValid;
exports.isDietValid = isDietValid;
exports.isExerciseValid = isExerciseValid;
exports.isCardiovascularValid = isCardiovascularValid;
exports.isConditionsValid = isConditionsValid;
exports.isValid = isValid;

exports.allFamilyMembersValid = (family) => {
    return family.length > 1 && family.every((person) => isValid(person));
};

exports.medicalHistories = ['healthy', 'unknown'];

exports.insurances = ['Medicare', 'Medicaid', 'Employer/private', 'Chips', 'Other', 'None'];

exports.genders = ['male', 'female', 'other', 'unknown'];

exports.genderIdentities = ['male', 'female', 'non-binary', 'transgender-male', 'transgender-female', 'other', 'non-disclose'];

exports.languages = ['en', 'es'];

exports.livings = ['Alive', 'Deceased', 'Unknown'];

exports.causesOfDeath = ['Accident', 'Homicide', 'Infection', 'Old Age/Natural Causes', 'Overdose', 'Pregnancy/Childbirth Related', 'SIDS', 'Sudden Death', 'Suicide', 'Unknown', 'Other'];

exports.educationLevels = ['High school or less', 'Community college', '4-year college', 'Graduate or professional school'];

exports.incomeLevels = ['Up to $20,000', '$20,001—$50,000', '$50,001—$100,000', 'Over $100,000'];

exports.races = ['American Indian or Alaska Native', 'Asian', 'Black or African American', 'Native Hawaiian or Other Pacific Islander', 'White', 'Other'];

exports.ethnicities = ['Hispanic or Latino', 'Not Hispanic or Latino', 'Ashkenazi Jewish'];

exports.typesOfWork = [
    'Airplane, satellite, or rocket builder',
    'Auto repair worker',
    'Cement production worker',
    'Chemical manufacture worker',
    'Electrician',
    'Electronics assembly',
    'Farmer or crop harvester',
    'Furniture maker or repairer',
    'Lawn or garden services',
    'Leather tanning and finishing',
    'Logger or sawmill worker',
    'Miner',
    'Office worker',
    'Other building construction labor',
    'Painter',
    'Plastic manufacture worker',
    'Plumber',
    'Police officer',
    'Printing or publishing paper, newspaper, books, etc',
    'Professional (lawyer, doctor, teacher, etc)',
    'Refinery worker',
    'Road, tunnel, or bridge construction labor',
    'Roofing/insulation',
    'Ship breaker or builder',
    'Solid waster incinerator operator',
    'Steel mill, foundry, or smelting worker',
    'Stone or brick worker',
    'Tire manufacture',
    'Textile worker',
    'Wall paper hanger',
    'Welder',
    'Other'
];

exports.caIMTs = ['normal', 'abnormal'];

exports.cacCTs = ['none', '0', '1-100', '101-300', '301+'];

exports.yesNo = ['Y', 'N'];

exports.yesNoUnknown = ['Y', 'N', 'unknown'];

exports.biopsies = ['1', '2', '3', '>3'];

exports.biopsyResults = ['hyperplasia', 'atypical_hyperplasia', 'unknown'];

exports.bothBreasts = [...exports.yesNoUnknown];

exports.cancerGrades = ['1', '2', '3', 'unknown'];

exports.erReceptorTypes = ['ER+', 'ER-', 'unknown'];

exports.prReceptorTypes = ['PR+', 'PR-', 'unknown'];

exports.her2ReceptorTypes = ['HER2+', 'HER2-', 'unknown'];

exports.numberOfMiscarriages = ['1', '2', '3', '4', '>4'];

exports.numberOfCancers = ['1', '>1'];

exports.numberOfSkinCancers = ['1', '2', '3', '4', '5', '>5'];

exports.bloodClotRisks = ['multiple', 'immobile', 'special', 'female'];

exports.glands = ['1', '2', '3', '4'];

exports.colonPolyps = ['<10', '10-50', '>50', 'unknown'];

exports.gleasonScores = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'unknown'];

exports.metastatic = [...exports.yesNo];

exports.lipomas = ['<3', '≥3'];

exports.mismatchRepairDeficiencies = [...exports.yesNoUnknown];

exports.categories = [
    'blood',
    'brain',
    'cancer',
    'cardiovascular',
    'circulatory',
    'common',
    'digestive',
    'eye',
    'female',
    'hereditary',
    'kidney',
    'liver',
    'lung',
    'musculoskeletal',
    // 'other',
    'psychological',
    'skin',
    'thyroid',
    'emerge'
];

exports.conditionGroups = [{ id: 'cancer', rule: (id) => /cancer/.test(id) && !/hereditary/.test(id) }];

exports.cdsResources = [
    {
        topics: ['genetic_counseling', 'genetic_testing', 'sharing', 'genetics'],
        triggers: [
            'CARDIO.5',
            'CARDIO.6',
            'CARDIO.85',
            'CARDIO.86',
            'CARDIO.87',
            'MHS.1',
            'MHS.2',
            'MHS.4',
            'Thromb.1',
            'Thromb.3',
            'Thromb.6',
            'Thromb.9',
            'Thromb.16',
            'Thromb.30',
            'Thromb.31',
            'Thromb.32',
            'Thromb.33',
            'CA.hboc.2',
            'CA.hboc.3',
            'CA.hboc.4',
            'CA.hboc.5',
            'CA.hboc.6',
            'CA.hboc.7',
            'CA.hboc.8',
            'CA.hboc.9',
            'CA.hboc.10',
            'CA.hboc.11',
            'CA.hboc.12',
            'CA.hboc.13',
            'CA.hboc.14',
            'CA.hboc.15',
            'CA.hboc.16',
            'CA.hboc.17',
            'CA.hboc.18',
            'CA.hboc.19',
            'CA.hboc.20',
            'CA.hboc.21',
            'CA.hboc.22',
            'CA.hboc.23',
            'CA.hboc.24',
            'CA.hboc.25',
            'CA.hboc.26',
            'CA.hboc.27',
            'CA.hboc.28',
            'CA.hboc.29',
            'CA.hboc.30',
            'CA.hboc.31',
            'CA.hered.1',
            'CA.hered.3',
            'CA.lynch.2',
            'CA.lynch.3',
            'CA.lynch.4',
            'CA.lynch.5',
            'CA.lynch.6',
            'CA.lynch.7',
            'CA.lynch.8',
            'CA.lynch.9',
            'CA.lynch.10',
            'CA.lynch.11',
            'CA.lynch.12',
            'CA.lynch.13',
            'CA.lynch.14',
            'CA.lynch.15',
            'CA.lynch.16',
            'CA.lynch.17',
            'CA.lynch.18',
            'CA.lynch.19',
            'CA.lynch.20',
            'CA.lynch.21',
            'CA.lynch.22',
            'CA.lynch.23',
            'CA.lynch.24',
            'CA.lynch.25',
            'CA.lynch.26',
            'CA.lynch.27',
            'CA.lynch.28',
            'CA.lynch.29',
            'CA.lynch.30',
            'CA.lynch.31',
            'CA.lynch.32',
            'CA.lynch.33',
            'CA.lynch.34',
            'CA.lynch.35',
            'CA.lynch.36',
            'CA.fap.2',
            'CA.fap.3',
            'CA.fap.4',
            'CA.lfs.2',
            'CA.lfs.3',
            'CA.lfs.4',
            'CA.lfs.5',
            'CA.lfs.6',
            'CA.lfs.7',
            'CA.lfs.8',
            'CA.lfs.9',
            'CA.lfs.10',
            'CA.lfs.11',
            'CA.lfs.12',
            'CA.lfs.13',
            'CA.lfs.14',
            'CA.lfs.15',
            'CA.lfs.16',
            'CA.lfs.17',
            'CA.lfs.18',
            'CA.cowden.2',
            'CA.cowden.3',
            'CA.cowden.4',
            'CA.nbccs.1',
            'CA.nbccs.2',
            'CA.nbccs.4',
            'CA.cmmrd.1',
            'CA.cmmrd.2',
            'CA.cmmrd.3',
            'CA.cmmrd.4',
            'CA.cmmrd.5',
            'CA.cmmrd.6',
            'CA.cmmrd.7',
            'CA.cmmrd.8',
            'CA.cmmrd.9',
            'CA.cmmrd.10',
            'CA.cmmrd.11',
            'CA.cmmrd.12',
            'CA.cmmrd.13',
            'CA.cmmrd.14',
            'CA.cmmrd.15',
            'CA.cmmrd.16',
            'CA.cmmrd.17',
            'CA.cmmrd.18',
            'CA.cmmrd.19',
            'CA.cmmrd.20',
            'CA.cmmrd.21',
            'CA.cmmrd.22',
            'CA.cmmrd.23',
            'CA.cmmrd.24',
            'CA.hdgc.1',
            'CA.hdgc.2',
            'CA.hdgc.3',
            'CA.hdgc.4',
            'CA.hdgc.5',
            'CA.hdgc.6',
            'CA.hdgc.7',
            'CA.hdgc.8',
            'CA.hdgc.9',
            'CA.hdgc.10',
            'CA.hdgc.11',
            'CA.hdgc.12',
            'CA.hdgc.13',
            'CA.hdgc.14',
            'CA.hdgc.16',
            'CA.famm.1',
            'CA.famm.2',
            'CA.famm.3',
            'CA.famm.4',
            'CA.famm.5',
            'CA.famm.6',
            'CA.famm.7',
            'CA.famm.9',
            'CA.famm.10',
            'CA.famm.11',
            'CA.fp.1',
            'CA.fp.2',
            'CA.vhl.1',
            'CA.vhl.2',
            'CA.vhl.3',
            'CA.vhl.4',
            'CA.vhl.5',
            'CA.vhl.6',
            'CA.vhl.8',
            'CA.hprc.1',
            'CA.hprc.2',
            'CA.hprc.4',
            'CA.hlrcc.1',
            'CA.hlrcc.2',
            'CA.hlrcc.4',
            'CA.hlrcc.5',
            'CA.bhd.1',
            'CA.bhd.2',
            'CA.bhd.4',
            'CA.men2.1',
            'CA.men2.2',
            'CA.men2.4',
            'CA.men1.1',
            'CA.men1.2',
            'CA.men1.3',
            'CA.men1.4',
            'CA.men1.5',
            'CA.men1.6',
            'CA.men1.7',
            'CA.men1.8',
            'CA.men1.10',
            'CA.pglpcc.1',
            'CA.pglpcc.2',
            'CA.pglpcc.3',
            'CA.pglpcc.4',
            'CA.pglpcc.6',
            'CA.rb.2',
            'CA.map.2',
            'CA.jp.2',
            'CA.pjs.2',
            'CA.tsc.2'
        ]
    },
    {
        topics: ['mri_bcra'],
        triggers: ['CA.brovca.99', 'CA.brovca.100', 'CA.brovca.101', 'CA.brovca.102', 'CA.brovca.103']
    },
    {
        topics: ['chemoprevention_bcra'],
        triggers: [
            'CA.chemoprev.3',
            'CA.chemoprev.4',
            'CA.chemoprev.5',
            'CA.chemoprev.7',
            'CA.chemoprev.8',
            'CA.chemoprev.9',
            'CA.chemoprev.10',
            'CA.chemoprev.13',
            'CA.chemoprev.14',
            'CA.chemoprev.15',
            'CA.chemoprev.16',
            'CA.chemoprev.17',
            'CA.chemoprev.18',
            'CA.chemoprev.19'
        ]
    },
    {
        topics: ['colonoscopy_crc'],
        triggers: [
            'CA.lynch.13',
            'CA.lynch.14',
            'CA.lynch.15',
            'CA.lynch.16',
            'CA.crc.1',
            'CA.crc.11',
            'CA.crc.16',
            'CA.crc.17',
            'CA.crc.18',
            'CA.crc.19',
            'CA.crc.22',
            'CA.crc.23',
            'CA.crc.24',
            'CA.crc.27',
            'CA.crc.28',
            'CA.crc.29',
            'CA.crc.31',
            'CA.crc.32',
            'CA.crc.33',
            'CA.crc.34',
            'CA.crc.42',
            'CA.crc.62'
        ]
    }
];

const cancerScreenConditions = [
    { order: 1, id: 'colonoscopy', categories: ['digestive'], ageLabel: 'fields.ageRecentColonoscopy', snomed: ['73761001', '24420007'] },
    { order: 2, id: 'nsaid_use', categories: ['digestive'], skipQuestions: true, snomed: ['387207008', '16403005'] },
    { order: 3, id: 'aspirin_use', categories: ['digestive'], skipQuestions: true, snomed: ['387458008', '717854002'] },
    { order: 4, id: 'breast_biopsy', categories: ['female'], meta: { biopsies: null, biopsyResult: null }, snomed: ['6703006', '369462000', '122548005'], gender: 'female' },
    { order: 5, id: 'menarche', categories: ['female'], snomed: ['20016009', '398700009'], gender: 'female' },
    { order: 6, id: 'menopause', categories: ['female'], snomed: ['161712005', '289903006', '773251000'], gender: 'female' },
    { order: 7, id: 'radiation_therapy_chest', categories: ['female'], snomed: ['77941000119104'], gender: 'female' },
    { order: 8, id: 'uterine_hyperplasia', categories: ['cancer', 'female'], snomed: ['16755041000119107'], gender: 'female' }
];
exports.cancerScreenConditions = cancerScreenConditions;

const pregnancyScreenConditions = [
    { order: 1, id: 'live_birth', categories: ['female'], snomed: ['726001007'], ageLabel: 'fields.ageFirstLiveBirth' },
    { order: 2, id: 'low_birth_weight_baby', categories: ['female'], snomed: ['726565008'], ageLabel: 'fields.ageLowBirthWeight' },
    { order: 3, id: 'miscarriage', categories: ['female'], meta: { numberOfMiscarriages: null }, snomed: ['17369002'], ageLabel: 'fields.ageFirstMiscarriage' },
    { order: 4, id: 'pre_eclampsia', categories: ['female'], snomed: ['398254007', '105651000119100', '69909000', '10752641000119102'] },
    { order: 5, id: 'pre_term_labor', categories: ['female'], snomed: ['6383007', '10761241000119104'] }
];
exports.pregnancyScreenConditions = pregnancyScreenConditions;

const conditions = [
    { id: 'abdominal_aortic_aneurysm', categories: ['circulatory', 'common', 'emerge'], snomed: ['233985008'] },
    { id: 'thoracic_aortic_aneurysm', categories: ['circulatory', 'common'], snomed: ['433068007'] },
    { id: 'addiction_drug', categories: ['psychological', 'brain'], snomed: ['66214007'], snomedExclude: ['724694006', '15167005'] },
    { id: 'addiction_alcohol', categories: ['psychological', 'brain'], snomed: ['7200002'], snomedExclude: ['97571000119109'] },
    { id: 'addiction_tobacco', categories: ['psychological', 'brain'], snomed: ['89765005'] },
    { id: 'adhd', categories: ['psychological', 'brain'], snomed: ['406506008'] },
    { id: 'adrenal_cortex_tumor', categories: ['cancer'], snomed: ['127022002'], snomedExclude: ['94160007'] },
    { id: 'alpha_1_antitrypsinase_deficiency', categories: ['liver', 'hereditary'], skipQuestions: true, snomed: ['30188007'] },
    { id: 'alport_syndrome', categories: ['kidney', 'emerge'], snomed: ['770414008'] },
    { id: 'arrhythmogenic_right_ventricular_dysplasia', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['253528005'] },
    { id: 'asthma', categories: ['lung', 'emerge'], snomed: ['195967001'] },
    { id: 'atrial_fibrillation', categories: ['cardiovascular', 'emerge'], snomed: ['49436004', '312442005'] },
    { id: 'autism', categories: ['psychological', 'brain'], snomed: ['408856003'], snomedExclude: ['733623005', '719600006', '770790004', '771512003', '766824003', '771448004', '783089006'] },
    { id: 'auto_immune_hepatitis', categories: ['liver'], snomed: ['408335007'] },
    { id: 'bipolar', categories: ['psychological', 'brain'], snomed: ['13746004'] },
    { id: 'birt_hogg_dube_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['110985001'] },
    { id: 'blindness', categories: ['eye'], snomed: ['193699007'] },
    { id: 'bone_cancer', categories: ['cancer'], snomed: ['93725000', '429247002'], snomedExclude: ['1099291000119102', '118600007', '11164009'] },
    {
        id: 'brain_cancer',
        categories: ['cancer', 'brain'],
        meta: { cancerTypes: [{ id: 'astrocytoma', snomed: ['254938000'], selected: null }] },
        snomed: ['254938000', '126952004', '416901002'],
        snomedExclude: ['767448007', '254956000', '691191000119103', '276836002', '118600007', '717003001', '722201004', '15863451000119107', '96981000119102']
    },
    {
        id: 'breast_cancer',
        categories: ['cancer', 'female', 'emerge'],
        meta: {
            cancerTypes: [
                { id: 'dcis', snomed: ['109889007', '88811000119104'], selected: null },
                { id: 'inv_ductal', snomed: ['408643008'], selected: null },
                { id: 'lcis', snomed: ['109888004', '143481000119103'], selected: null },
                { id: 'inv_lobular', snomed: ['278054005'], selected: null }
            ],
            bothBreasts: null,
            cancerGrade: null,
            erReceptorType: null,
            prReceptorType: null,
            her2ReceptorType: null
        },
        snomed: ['254838004', '429087003', '109889007', '88811000119104', '408643008', '109888004', '143481000119103', '278054005']
    },
    { id: 'brugada', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['418818005'] },
    { id: 'carcinoid_tumor', categories: ['cancer', 'digestive'], snomed: ['443492008', '59651000119104', '286771000119106', '286791000119107', '286781000119109', '10987961000119105'], snomedExclude: ['703321001'] },
    { id: 'carotid_artery_stenosis', categories: ['circulatory', 'emerge'], snomed: ['69798007', '64586002', '789642001'], snomedExclude: ['724097003'] },
    { id: 'catecholaminergic_polymorphic_ventricular_tachycardia', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['419671004'] },
    {
        id: 'colon_cancer',
        categories: ['cancer', 'digestive', 'emerge'],
        meta: { mismatchRepairDeficiency: null, numberOfCancers: null },
        snomed: ['363406005', '429699009', '93854002'],
        snomedExclude: ['94260004', '133751000119102', '766981007', '315058005', '126848003', '93984006', '109839004', '10987871000119109', '428701004', '133531000119104'],
        snomedExcludeConditions: [/^carcinoid_/]
    },
    { id: 'colon_polyp', categories: ['digestive', 'emerge'], meta: { colonPolyps: null }, snomed: ['68496003', '429047008'] },
    { id: 'copd_chronic_bronchitis_emphysema', categories: ['lung'], snomed: ['13645005', '270473001'] },
    { id: 'cowden_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['58037000'] },
    { id: 'crohns_disease', categories: ['digestive'], snomed: ['34000006', '112561000119108'] },
    { id: 'cystic_kidney_disease', categories: ['kidney', 'emerge'], snomed: ['722223000', '765331004'], snomedExclude: ['723409007', '733650000', '771149000', '11164009'] },
    {
        id: 'dvt',
        categories: ['circulatory', 'blood', 'hereditary'],
        meta: {
            dvtTypes: [
                { id: 'factor_v', snomed: ['307091009'], selected: null },
                { id: 'pt_mutation', snomed: ['440989002'], selected: null },
                { id: 'at_iii_deficiency', snomed: ['36351005'], selected: null },
                { id: 'protein_s_deficiency', snomed: ['1563006'], selected: null },
                { id: 'protein_c_deficiency', snomed: ['76407009'], selected: null }
            ],
            bloodClotRisks: []
        },
        snomed: ['128053003', '161508001', '307091009', '440989002', '36351005', '1563006', '76407009']
    },
    { id: 'dementia', categories: ['brain', 'common'], snomed: ['52448006', '161465002'], snomedExclude: ['56267009', '724992007', '82381000119103', '11164009'] },
    { id: 'depression', categories: ['psychological', 'brain'], snomed: ['35489007', '161469008'], snomedExclude: ['56267009', '767631007'] },
    { id: 'diabetes_gestational', categories: ['common'], snomed: ['11687002', '472971004'] },
    { id: 'diabetes_type_1', categories: ['common', 'emerge'], meta: { a1c: null, a1cTestYear: null }, snomed: ['46635009', '472970003'], snomedExclude: ['722454003'] },
    { id: 'diabetes_type_2', categories: ['common', 'emerge'], meta: { a1c: null, a1cTestYear: null }, snomed: ['44054006', '472969004'] },
    { id: 'diabetic_kidney_disease', categories: ['kidney', 'emerge'], snomed: ['127013003'] },
    { id: 'dilated_cardiomyopathy', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['399020009'] },
    { id: 'eating_disorder', categories: ['psychological', 'brain'], snomed: ['72366004', '126131000119101'] },
    { id: 'ehlers_danlos_syndrome', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['398114001'] },
    { id: 'esophageal_cancer', categories: ['cancer', 'digestive'], snomed: ['371984007', '429410000', '255078002'], snomedExclude: ['133531000119104'] },
    { id: 'eczema', categories: ['skin', 'common', 'emerge'], snomed: ['43116000'] },
    { id: 'familial_adenomatous_polyposis', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['72900001'] },
    { id: 'familial_hypercholesterolemia', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['398036000'] },
    { id: 'gastric_surgery', categories: ['digestive', 'common', 'emerge'], snomed: ['698450007', '18692006', '708983005'] },
    { id: 'glaucoma', categories: ['eye'], snomed: ['23986001', '161488001'], snomedExclude: ['415501002', '721974000', '733116005', '733455003', '733086003', '11164009'] },
    { id: 'heart_attack_coronary_artery_disease', categories: ['cardiovascular', 'common', 'emerge'], snomed: ['53741008', '414545008', '428375006'] },
    { id: 'hemorrhagic_stroke', categories: ['circulatory', 'brain'], snomed: ['230706003', '141811000119106', '118961000119101'] },
    { id: 'hereditary_breast_and_ovarian_cancer_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['254843006', '718220008', '445333001', '412734009', '412738007'] },
    { id: 'hereditary_diffuse_gastric_cancer', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['716859000'] },
    { id: 'hereditary_hemochromatosis', categories: ['hereditary', 'liver'], skipQuestions: true, snomed: ['35400008'] },
    { id: 'hereditary_leiomyomatosis_and_renal_cell_carcinoma_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: [''] },
    { id: 'hereditary_melanoma', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: [''] },
    { id: 'hereditary_papillary_renal_cancer_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['715561008'] },
    { id: 'hereditary_paraganglioma_pheochromocytoma_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['716857003'] },
    { id: 'hereditary_retinoblastoma', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: [''] },
    { id: 'high_cholesterol', categories: ['cardiovascular', 'common', 'emerge'], snomed: ['55822004', '414416008'], snomedExcludeConditions: [/^familial_hypercholesterolemia$/] },
    { id: 'hypertension', categories: ['cardiovascular', 'common', 'emerge'], snomed: ['38341003', '10752641000119102'], snomedExclude: ['720568003', '766937004'] },
    { id: 'hypertensive_kidney_disease', categories: ['kidney', 'emerge'], snomed: ['104931000119100'] },
    { id: 'hypertrophic_cardiomyopathy', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['233873004'] },
    { id: 'hysterectomy', categories: ['female'], snomed: ['236886002', '161800001'] },
    { id: 'iga_nephropathy', categories: ['kidney', 'emerge'], snomed: ['68779003'] },
    {
        id: 'intellectual_disability',
        categories: ['psychological', 'brain'],
        snomed: ['228156007', '110359009'],
        snomedExclude: [
            '734349003',
            '236529001',
            '720748007',
            '721073008',
            '723333000',
            '723336008',
            '763615003',
            '764732004',
            '771179007',
            '773587008',
            '720639008',
            '719380003',
            '723304001',
            '699298009',
            '702344008',
            '109478007',
            '733031004',
            '733032006',
            '719810000',
            '721207002',
            '722110003',
            '723676007',
            '725163002',
            '763861000',
            '765089003',
            '765170001',
            '768473009',
            '770431001',
            '773230003',
            '773498006',
            '773548008',
            '770755007',
            '770898002',
            '773699009',
            '782772000',
            '780827006',
            '785726009',
            '788417006',
            '716706009',
            '718577005',
            '719825000',
            '722033000',
            '733417008',
            '763795006',
            '763773007',
            '718896000',
            '719160009',
            '722037004',
            '763350002',
            '774102003',
            '776204008',
            '719834005',
            '719600006',
            '770790004',
            '771512003',
            '766824003',
            '720982007',
            '733472005',
            '721974000',
            '733116005',
            '733455003',
            '733086003',
            '724137002',
            '770750002',
            '726031001',
            '771476007',
            '771149000',
            '771448004',
            '778025006',
            '783089006',
            '11164009',
            '715628009'
        ]
    },
    { id: 'irritable_bowel_syndrome', categories: ['digestive'], snomed: ['10743008', '70871000119100'] },
    { id: 'ischemic_stroke', categories: ['circulatory', 'brain', 'emerge'], snomed: ['230690007'], snomedExclude: ['384993003'] },
    { id: 'juvenile_polyposis', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['9273005'] },
    {
        id: 'kidney_cancer',
        categories: ['cancer', 'kidney'],
        meta: {
            cancerTypes: [
                { id: 'clear_cell', snomed: ['254915003'], selected: null },
                { id: 'papillary_1', snomed: ['254917006'], selected: null },
                { id: 'papillary_2', snomed: ['254917006'], selected: null },
                { id: 'tubulopapillary', snomed: ['254917006'], selected: null },
                { id: 'collecting_duct', snomed: ['128669006'], selected: null },
                { id: 'chromophobe', snomed: ['1443001', '733471003'], selected: null },
                { id: 'hybrid', snomed: ['254922006'], selected: null }
            ],
            numberOfCancers: null
        },
        snomed: ['93849006', '254917006', '254915003', '128669006', '1443001', '254922006'],
        snomedExclude: ['118600007', '713574000']
    },
    { id: 'kidney_dialysis', categories: ['kidney', 'emerge'], snomed: ['265764009'] },
    { id: 'kidney_transplant', categories: ['kidney', 'emerge'], snomed: ['236436003'] },
    { id: 'kidney_malformed', categories: ['kidney', 'emerge'], snomed: ['92921005', '204984002', '204941003', '204941003', '32659003', '204942005'] },
    { id: 'proteinuria', categories: ['kidney', 'emerge'], snomed: ['29738008'], snomedExcludeSameCategories: true },
    { id: 'chronic_kidney_disease', categories: ['kidney', 'emerge'], meta: { other: null }, snomed: ['46177005', '709044004'], snomedExcludeSameCategories: true },
    { id: 'focal_segmental_glomerulosclerosis', categories: ['kidney', 'emerge'], snomed: ['236403004'] },
    { id: 'left_ventricular_non_compaction_syndrome', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['447935001'] },
    { id: 'leukemia', categories: ['cancer', 'blood'], snomed: ['93143009', '161436008'] },
    { id: 'li_fraumeni_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['428850001'] },
    { id: 'lipoma', categories: ['cancer', 'skin'], meta: { lipomas: null }, snomed: ['93163002', '16900351000119101', '695751021000132104'], snomedExclude: ['722201004', '11164009'] },
    { id: 'liver_cancer', categories: ['cancer', 'liver'], snomed: ['95214007'], snomedExclude: ['716652006'] },
    { id: 'long_qt', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['9651007'] },
    { id: 'lung_cancer', categories: ['cancer', 'lung'], snomed: ['93734005', '93880001', '429226001'], snomedExclude: ['722527003', '722528008', '118600007'] },
    { id: 'lupus', categories: ['musculoskeletal', 'skin'], snomed: ['200936003'] },
    { id: 'lupus_kidney_disease', categories: ['kidney', 'emerge'], snomed: ['68815009'] },
    { id: 'lymphoma', categories: ['blood'], snomed: ['118600007', '429014004'], snomedExclude: ['188725004'] },
    { id: 'lynch_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['315058005', '716318002', '1099611000119109'] },
    { id: 'macrocephaly', categories: ['brain'], snomed: ['19410003'], snomedExclude: ['718577005', '719825000', '722033000', '733417008', '763795006', '763773007', '718896000', '724137002', '770750002', '783089006', '11164009'] },
    { id: 'macular_degeneration', categories: ['eye'], snomed: ['267718000', '16535401000119102'] },
    { id: 'malignant_hyperthermia_susceptibility', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['405501007'] },
    { id: 'marfan_syndrome', categories: ['cardiovascular', 'hereditary'], skipQuestions: true, snomed: ['19346006'] },
    { id: 'membranous_nephropathy', categories: ['kidney', 'emerge'], snomed: ['77182004'], snomedExclude: ['52042003'] },
    { id: 'multiple_endocrine_neoplasia_type_1', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['30664006'] },
    { id: 'multiple_endocrine_neoplasia_type_2', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['61808009'] },
    { id: 'multiple_sclerosis', categories: ['brain', 'musculoskeletal'], snomed: ['24700007'] },
    { id: 'muscle_cancer', categories: ['cancer', 'musculoskeletal'], snomed: ['363495004'], snomedExclude: ['94432003', '187786003'] },
    { id: 'mutyh_associated_polyposis', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['423471004'] },
    {
        id: 'neuroendocrine_tumor',
        categories: ['cancer'],
        snomed: ['255046005', '55937004'],
        snomedExclude: ['255032005', '277505007', '93727008', '93987004', '416901002', '699318007', '715904005', '766247009', '766248004', '716857003', '722377004'],
        snomedExcludeConditions: [/^carcinoid_/, /^paraganglioma$/, /^pheochromoctyoma$/]
    },
    { id: 'nevoid_basal_cell_carcinoma_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['69408002'] },
    {
        id: 'obesity',
        categories: ['common', 'emerge'],
        meta: { maxHeight: null, maxWeight: null },
        snomed: ['414916001', '161453001'],
        snomedExclude: ['722051004', '719160009', '722037004', '763350002', '774102003', '776204008', '719834005', '724137002', '770750002', '11164009', '715628009']
    },
    { id: 'obsessive_compulsive_disorder', categories: ['psychological', 'brain'], snomed: ['191736004'] },
    { id: 'osteoporosis', categories: ['common', 'musculoskeletal'], snomed: ['64859006', '473120007'] },
    {
        id: 'other_hereditary_disease',
        categories: ['hereditary'],
        meta: { other: null },
        skipAge: true,
        snomed: [
            '363005004',
            '723304001',
            '11164009',
            '699298009',
            '734349003',
            '702344008',
            '109478007',
            '733031004',
            '733032006',
            '719810000',
            '721207002',
            '722110003',
            '723676007',
            '725163002',
            '763861000',
            '765089003',
            '765170001',
            '768473009',
            '770431001',
            '773230003',
            '773498006',
            '773548008',
            '770755007',
            '770898002',
            '773699009',
            '782772000',
            '780827006',
            '785726009',
            '788417006',
            '716706009',
            '718577005',
            '719825000',
            '722033000',
            '733417008',
            '763795006',
            '763773007',
            '718896000',
            '719160009',
            '722037004',
            '763350002',
            '774102003',
            '776204008',
            '719834005',
            '719600006',
            '770790004',
            '771512003',
            '766824003',
            '720982007',
            '733472005',
            '721974000',
            '733116005',
            '733455003',
            '733086003',
            '724137002',
            '770750002',
            '726031001',
            '771476007',
            '771149000',
            '771448004',
            '778025006',
            '783089006',
            '717003001',
            '720519003',
            '720568003',
            '724097003',
            '733623005',
            '733650000',
            '765331004',
            '770414008',
            '773647007',
            '367531000119106',
            '715628009',
            '771080008',
            '723409007',
            '399340005'
        ],
        snomedExclude: ['783161005', '719380003', '402851000', '717003001', '720519003', '720568003', '724097003', '367531000119106', '726031001', '771476007'],
        snomedExcludeSameCategories: true,
        snomedExcludeConditions: [/^thalassemia$/]
    },
    { id: 'other', categories: [], meta: { other: null }, snomed: ['722201004'], snomedExclude: ['723409007', '360353005'] },
    {
        id: 'ovarian_cancer',
        categories: ['cancer', 'female', 'emerge'],
        meta: { cancerTypes: [{ id: 'high_grade_serous', snomed: ['722685004'], selected: null }] },
        snomed: ['363443007', '429090009', '722685004'],
        snomedExclude: ['94455000', '118600007', '771080008', '718220008']
    },
    { id: 'pancreatic_cancer', categories: ['cancer', 'digestive', 'emerge'], snomed: ['363418001'], snomedExclude: ['94459006', '133531000119104'] },
    { id: 'panic_disorder', categories: ['psychological', 'brain'], snomed: ['371631005'] },
    { id: 'paraganglioma', categories: ['cancer'], snomed: ['302833002'], snomedExclude: ['716857003', '11164009'] },
    { id: 'parathyroid_adenoma', categories: ['cancer'], meta: { glands: null }, snomed: ['92272009'] },
    { id: 'peripheral_arterial_disease', categories: ['circulatory', 'emerge'], snomed: ['399957001', '840580004', '431545001', '789544005'] },
    { id: 'personality_disorder', categories: ['psychological', 'brain'], snomed: ['33449004'], snomedExclude: ['442057004'], snomedExcludeConditions: [/^schizophrenia$/] },
    { id: 'peutz_jeghers_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['54411001'] },
    { id: 'pheochromoctyoma', categories: ['cancer'], snomed: ['302835009', '85583005', '49221000119108'], snomedExclude: ['716857003'] },
    { id: 'pituitary_adenoma', categories: ['cancer', 'brain'], snomed: ['254956000', '691191000119103'], snomedExclude: ['11164009'] },
    { id: 'primary_biliary_cirrhosis', categories: ['liver'], snomed: ['31712002'] },
    {
        id: 'prostate_cancer',
        categories: ['cancer', 'emerge'],
        meta: { gleasonScore: null, metastatic: null },
        snomed: ['399068003'],
        snomedExclude: ['715412008', '399590005', '369485004', '94503003', '449318001', '278060005', '823017009', '396198006']
    },
    { id: 'ptsd', categories: ['psychological', 'brain'], snomed: ['47505003'] },
    { id: 'rectal_cancer', categories: ['cancer', 'digestive'], snomed: ['363351006', '429084005'], snomedExclude: ['94513006', '96281000119107', '276822007', '428701004', '286791000119107', '133531000119104'] },
    { id: 'retinal_detachment', categories: ['eye'], snomed: ['42059000', '161487006'], snomedExclude: ['11164009'] },
    { id: 'retinoblastoma', categories: ['cancer', 'eye'], snomed: ['371986009', '19906005'], snomedExclude: ['416901002', '231833004', '118600007', '274087000'] },
    { id: 'rheumatoid_arthritis', categories: ['lung', 'musculoskeletal'], snomed: ['69896004', '161567008'] },
    { id: 'schizophrenia', categories: ['psychological', 'brain'], snomed: ['58214004', '161468000'] },
    { id: 'sclerosing_cholangitis', categories: ['liver'], snomed: ['197441003'] },
    {
        id: 'seizure',
        categories: ['brain'],
        snomed: ['91175000', '84757009', '161480008'],
        snomedExclude: [
            '69909000',
            '723304001',
            '720519003',
            '733623005',
            '702344008',
            '109478007',
            '733031004',
            '733032006',
            '719810000',
            '721207002',
            '722110003',
            '723676007',
            '725163002',
            '763861000',
            '765089003',
            '765170001',
            '768473009',
            '770431001',
            '773230003',
            '773498006',
            '773548008',
            '770755007',
            '770898002',
            '773699009',
            '782772000',
            '780827006',
            '785726009',
            '788417006',
            '716706009',
            '771448004',
            '11164009'
        ]
    },
    { id: 'sickle_cell_disease', categories: ['blood'], skipQuestions: true, snomed: ['417357006'] },
    { id: 'sickle_cell_trait', categories: ['blood'], skipQuestions: true, snomed: ['16402000'] },
    { id: 'thalassemia', categories: ['blood'], skipQuestions: true, snomed: ['40108008'] },
    {
        id: 'skin_cancer',
        categories: ['cancer', 'skin'],
        meta: {
            cancerTypes: [
                { id: 'melanoma', snomed: ['372244006', '161432005', '2092003'], selected: null },
                { id: 'non_melanoma', snomed: ['429114002', '428053000', '254651007', '429024007'], selected: null },
                { id: 'cutaneous_leiomyoma', snomed: ['254767008'], selected: null }
            ],
            numberOfSkinCancers: null, // deprecated
            numberOfSkinCancersMelanoma: null,
            numberOfSkinCancersNonMelanoma: null
        },
        snomed: ['372130007', '372244006', '161432005', '2092003', '429114002', '428053000', '254651007', '429024007', '254767008'],
        snomedExclude: ['253001006', '109978004', '448447004', '402882001', '404138009', '403458008', '69408002', '11164009']
    },
    {
        id: 'small_bowel_cancer',
        categories: ['cancer', 'digestive'],
        snomed: ['363509000', '428833003', '255078002'],
        snomedExclude: ['94580002', '428701004', '133531000119104', '286781000119109', '187786003', '118600007'],
        snomedExcludeConditions: [/^pancreatic_cancer/]
    },
    { id: 'social_phobia', categories: ['psychological', 'brain'], snomed: ['25501002'] },
    {
        id: 'stomach_cancer',
        categories: ['cancer', 'digestive'],
        meta: { numberOfCancers: null },
        snomed: ['363349007', '126824007', '473061005', '255078002'],
        snomedExclude: ['716859000', '94606003', '721194008', '428701004', '133531000119104', '286781000119109', '276811008', '118600007', '187724003', '695751021000132104', '10987961000119105']
    },
    { id: 'hyperthyroidism', categories: ['thyroid', 'common'], snomed: ['34486009', '161442007', '360353005'], snomedExclude: ['11164009'] },
    { id: 'hypothyroidism', categories: ['thyroid', 'common'], snomed: ['40930008', '161443002'], snomedExclude: ['360353005', '722051004', '699298009', '11164009'] },
    {
        id: 'thyroid_cancer',
        categories: ['cancer', 'thyroid'],
        meta: {
            cancerTypes: [
                { id: 'medullary', snomed: ['255032005', '429016002'], selected: null },
                { id: 'non_medullary', snomed: ['255028004', '255029007', '429255009', '428064002'], selected: null }
            ]
        },
        snomed: ['255032005', '429016002', '255028004', '255029007', '429255009', '428064002', '94098005', '429254008']
    },
    { id: 'thyroid_nodule', categories: ['thyroid'], snomed: ['237495005', '419153005'], snomedExclude: ['723409007'] },
    { id: 'tuberous_sclerosis_complex', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['7199000', '765331004'] },
    { id: 'ulcerative_colitis', categories: ['digestive'], snomed: ['64766004', '275549008'] },
    {
        id: 'uterine_cancer',
        categories: ['cancer', 'female', 'emerge'],
        meta: { mismatchRepairDeficiency: null, numberOfCancers: null },
        snomed: ['371973000', '428944005', '428941002'],
        snomedExclude: ['94665001', '133531000119104', '118600007', '429090009', '672291000119107', '141951000119104']
    },
    { id: 'von_hippel_lindau_syndrome', categories: ['hereditary', 'cancer'], skipQuestions: true, snomed: ['46659004'] },
    { id: 'wilsons_disease', categories: ['liver', 'hereditary'], skipQuestions: true, snomed: ['88518009'] }
];
exports.conditions = conditions;

exports.optionalMetaProps = ['a1c', 'a1cTestYear', 'maxHeight', 'maxWeight', 'numberOfSkinCancers', 'numberOfSkinCancersMelanoma', 'numberOfSkinCancersNonMelanoma'];

const cancerScreenConditionsById = keyBy(cancerScreenConditions, 'id');
exports.cancerScreenConditionsById = cancerScreenConditionsById;
const pregnancyScreenConditionsById = keyBy(pregnancyScreenConditions, 'id');
exports.pregnancyScreenConditionsById = pregnancyScreenConditionsById;
const conditionsById = keyBy(conditions, 'id');
exports.conditionsById = conditionsById;

exports.allConditionsById = Object.assign({}, cancerScreenConditionsById, pregnancyScreenConditionsById, conditionsById);

exports.conditionsBySnomed = () => {
    const conditionsBySnomed = {};
    each(conditionSnomed, (condition) => {
        each(condition.snomed, (snomed) => {
            if (!conditionsBySnomed[snomed]) {
                conditionsBySnomed[snomed] = [];
            }
            const _condition = cloneDeep(conditionsById[condition.id]);
            _condition.snomedInclude = condition.snomed;
            conditionsBySnomed[snomed].push(_condition);
        });
    });
    return conditionsBySnomed;
};

exports.copyCondition = (condition) => {
    const _condition = Object.assign(cloneDeep(omit(condition, ['name', 'short', 'hint', 'keywords', 'categories', 'snomed', 'snomedExclude', 'snomedExcludeConditions', 'snomedExcludeSameCategories', 'snomedInclude'])), {
        age: null,
        uuid: uuidv4()
    });
    if (_condition.meta) {
        if (_condition.meta.cancerTypes) {
            each(_condition.meta.cancerTypes, (type) => {
                delete type.snomed;
            });
        }
        if (_condition.meta.dvtTypes) {
            each(_condition.meta.dvtTypes, (type) => {
                delete type.snomed;
            });
        }
    }
    return _condition;
};

const decorateCondition = (condition, i18nFn) => {
    const name = i18nFn(`conditions.${condition.id}.name`);
    const short = i18nFn(`conditions.${condition.id}.short`);
    const hint = i18nFn(`conditions.${condition.id}.hint`);
    const keywords = i18nFn(`conditions.${condition.id}.keywords`);
    return Object.assign(cloneDeep(condition), { name, short, hint, keywords });
};
exports.decorateCondition = decorateCondition;

exports.decoratedConditions = (i18nFn) => {
    return conditions.map((condition) => decorateCondition(condition, i18nFn));
};

exports.studyConditions = (studyId, patient) => {
    if ('emerge_r4' === studyId) {
        let conditionList = [
            'abdominal_aortic_aneurysm',
            'alport_syndrome',
            'asthma',
            'atrial_fibrillation',
            'breast_cancer',
            'carotid_artery_stenosis',
            'chronic_kidney_disease',
            'colon_cancer',
            'colon_polyp',
            'cystic_kidney_disease',
            'diabetes_type_1',
            'diabetes_type_2',
            'diabetic_kidney_disease',
            'eczema',
            'focal_segmental_glomerulosclerosis',
            'gastric_surgery',
            'heart_attack_coronary_artery_disease',
            'high_cholesterol',
            'hypertension',
            'hypertensive_kidney_disease',
            'iga_nephropathy',
            'ischemic_stroke',
            'kidney_dialysis',
            'kidney_malformed',
            'kidney_transplant',
            'lupus_kidney_disease',
            'membranous_nephropathy',
            'obesity',
            'ovarian_cancer',
            'pancreatic_cancer',
            'peripheral_arterial_disease',
            'prostate_cancer',
            'proteinuria',
            'uterine_cancer'
        ];
        if (patient.age < 18 || (patient.tags && patient.tags.find((tag) => 'classification' === tag.name && 'pediatric' === tag.value))) {
            conditionList = ['asthma', 'diabetes_type_1', 'diabetes_type_2', 'eczema', 'gastric_surgery', 'obesity'];
        }
        return this.conditions.filter((condition) => includes(conditionList, condition.id));
    }
    return conditions;
};

const studyParameters = (studyId) => {
    if ('emerge_r4' === studyId) {
        return {
            requiredTags: ['participant_lab_id'],
            optionalTags: ['classification'],
            push: 56,
            lock: 56,
            risk: false,
            cds: false,
            resource: false
        };
    } else if ('moonshot' === studyId) {
        return {
            requiredTags: ['redcap'],
            optionalTags: [],
            push: 21,
            lock: 21,
            risk: true,
            cds: true,
            resource: true
        };
    } else if ('grace' === studyId) {
        return {
            requiredTags: ['redcap'],
            optionalTags: ['MRN'],
            cdsEndpointPathReplace: { from: '/metree/', to: '/metree3/' },
            pushWhenFinished: true,
            lockWhenFinished: true,
            push: 21,
            lock: 21,
            risk: true,
            cds: true,
            resource: true
        };
    }
    return {
        requiredTags: [],
        optionalTags: [],
        push: null,
        lock: null,
        risk: true,
        cds: true,
        resource: true
    };
};
exports.studyParameters = studyParameters;
const studyDays = (patientStudy = {}) => {
    return moment().diff(moment(patientStudy.started), 'days');
};
exports.studyDays = studyDays;
exports.isStudyLocked = (patientStudy = {}) => {
    if (patientStudy.hasOwnProperty('locked')) {
        return patientStudy.locked;
    }
    const parameters = studyParameters(patientStudy.id);
    const days = studyDays(patientStudy);
    return !!parameters.lock && days >= parameters.lock;
};

exports.studies = ['emerge_r4', 'moonshot', 'singapore', 'grace'];
