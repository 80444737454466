<template>
    <v-dialog v-model="dialog" :max-width="600">
        <v-card v-if="condition">
            <v-toolbar dense dark color="primary">
                <v-toolbar-title @click.shift="showSnomed = !showSnomed">{{ $t(`conditions.${condition.id}.name`) }}</v-toolbar-title>
                <v-toolbar-items class="align-center" v-if="showSnomed">
                    <v-divider vertical inset class="mx-2"></v-divider>
                    <div v-for="(snomed, index) in condition.snomed" :key="index">
                        <span class="mx-1 grey--text" v-if="index > 0"> | </span>
                        <a class="white--text" @click="setMedline(snomed)">{{ snomed }}</a>
                    </div>
                </v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon @click="close()">
                    <v-icon small>fas fa-times</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pa-6" style="max-height: 600px; overflow-y: scroll">
                <v-skeleton-loader v-if="!fetched" tile type="article@3"></v-skeleton-loader>
                <div v-else-if="medline">
                    <div class="medline-title" v-if="title">
                        <a target="_blank" :href="link" v-if="link">{{ title }}</a>
                        <span v-else>{{ title }}</span>
                    </div>
                    <div class="medline-summary" v-html="summary"></div>
                </div>
                <div v-else>
                    <div class="medline-none">{{ $t('errors.noMedline') }}</div>
                </div>
            </v-card-text>
            <div class="grey lighten-3 py-2 px-4 caption text-right" v-html="$t('labels.medlinePlus')"></div>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from 'axios';
    import { get } from 'lodash';
    import { allConditionsById } from '@/common';

    const cleanse = (html) => {
        return unescape(html).replace(/<a href=/g, '<a target="_blank" href=');
    };

    export default {
        data: () => ({
            dialog: false,
            condition: null,
            fetched: false,
            medline: null,
            showSnomed: false
        }),
        computed: {
            lang() {
                return this.$store.get('family@0.language');
            },
            title() {
                return this.medline ? get(this.medline, 'title._value') : null;
            },
            link() {
                return this.medline.link ? this.medline.link[0].href : null;
            },
            summary() {
                return this.medline ? cleanse(get(this.medline, 'summary._value')) : '';
            }
        },
        methods: {
            async fetchMedline(snomed) {
                if (snomed) {
                    const url = `https://connect.medlineplus.gov/service?mainSearchCriteria.v.cs=2.16.840.1.113883.6.96&mainSearchCriteria.v.c=${snomed}&informationRecipient.languageCode.c=${this.lang}&knowledgeResponseType=application/json`;
                    return await axios.get(url, { headers: null });
                }
                return null;
            },
            async fetchMedlineAll(snomeds = []) {
                try {
                    const results = await Promise.allSettled(snomeds.map(async (snomed) => await this.fetchMedline(snomed)));
                    const returned = results.filter(({ status }) => 'fulfilled' === status);
                    for (const { value: result } of returned) {
                        if (result) {
                            const { data: response } = result;
                            if (response?.feed?.entry && response.feed.entry.length) {
                                // return the first entry of the first successful call
                                return response.feed.entry[0];
                            }
                        }
                    }
                    return null;
                } catch (error) {
                    return null;
                }
            },
            async setMedline(snomed) {
                this.fetched = false;
                this.medline = null;
                const { data } = await this.fetchMedline(snomed);
                if (data?.feed?.entry && data.feed.entry.length) {
                    this.medline = data.feed.entry[0];
                }
                this.fetched = true;
            },
            async show(condition) {
                this.fetched = false;
                this.medline = null;

                this.condition = allConditionsById[condition.id];
                this.dialog = true;

                this.medline = await this.fetchMedlineAll(this.condition?.snomed);
                this.fetched = true;
            },
            close() {
                this.dialog = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
    ::v-deep {
        a {
            text-decoration: none;
        }
        a[href^="//"]:after,
        a[href^="http://"]:after,
        a[href^="https://"]:after
        {
            font-family: 'Font Awesome 5 Pro', sans-serif;
            content: ' \f35d ';
            font-size: small;
            margin-right: 5px;
        }
    }
    .medline-none {
        margin: 1em 0;
        font-size: 120%;
        text-align: center;
    }
    .medline-title {
        color: var(--v-primary-base);
        margin-bottom: 1em;
        font-size: 120%;
        font-weight: bold;
    }
    .medline-summary {
        ::v-deep {
            ul {
                margin: 1em 0 1em 1em;
            }

            .NLMattribution {
                color: var(--v-primary-base);
            }
        }
    }
</style>
